@font-face {
    font-family: 'DancingScript-Regular';
    src: local('DancingScript-Regular'), url('../../fonts/DancingScript-Regular.ttf') format('truetype');
}

.dancingScript {
    font-family: 'DancingScript-Regular';
}

.alignRight {
    text-align: right;
}

.centered {
    text-align: center;
}

.menuCol {
    padding-left: 0% !important;
    padding-right: 0% !important;
}

.pageCol {
    margin-top: 2% !important;
}

.servicesRows {
    margin-top: 30px !important;
}
